<template>
  <div>
    <video id="video" ref="videoPlayer" :src="hlsUrl" controls muted autoplay style="width: 100%; height: 97%">
    </video>
  </div>
</template>
<script>
import Hls from 'hls.js';
import { mapState, mapActions } from 'vuex'

export default {
  name: 'HlsPlayer',
  props: {
    hlsUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      blocked: true,
      hls: null,
      intervalo: null,
    }
  },
  computed: {
    ...mapState('cameras', ['cameraTime']),
  },
  watch: {
    cameraTime() {
      this.blocked = true
      try {
        const video = this.$refs.videoPlayer;
        video.currentTime = video.duration - this.cameraTime
      } catch (error) {
        console.log('error', error)
      }
    },
  },
  methods: {
    ...mapActions('cameras', ['setCameraTime']),
  },
  beforeDestroy() {
    this.hls.destroy()
    clearInterval(this.intervalo)
  },
  mounted() {
    const video = this.$refs.videoPlayer;
    if (Hls.isSupported()) {
      const hls = new Hls();
      this.hls = hls
      try {
        hls.loadSource(this.hlsUrl);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const isplaying = !video.paused && !video.ended && video.readyState > 2
          if (!isplaying) {
            video.play();
          }
        });

        video.addEventListener('loadeddata', () => {
          video.currentTime = video.duration - this.cameraTime
        });

        video.addEventListener('seeked', () => {
          if (this.blocked === true) {
            this.blocked = false
            return
          }
          this.blocked = true
          this.setCameraTime(video.duration - video.currentTime)
          this.blocked = false
        });

        video.addEventListener('close', () => {
          hls.destroy();
        });

        this.intervalo = setInterval(() => {
          console.log('intervalo')
          const isplaying = !video.paused && !video.ended && video.readyState > 2
          if (!isplaying) {
            try {
              hls.loadSource(this.hlsUrl);
              hls.attachMedia(video);
            } catch (error) {
              console.log('error', error);
            }
          }
        }, 10000);
      } catch (error) {
        console.log('mailios', error)
      }
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = this.hlsUrl;
      video.addEventListener('loadedmetadata', () => {
        video.play();
      });
      video.addEventListener('seeked', () => {
        console.log('Hola');
      });
    }
  },
};
</script>

<style lang="css" scoped>
#video-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

#video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
