import { render, staticRenderFns } from "./WidgetBox.vue?vue&type=template&id=693eaf52&scoped=true"
import script from "./WidgetBox.vue?vue&type=script&lang=js"
export * from "./WidgetBox.vue?vue&type=script&lang=js"
import style0 from "./WidgetBox.vue?vue&type=style&index=0&id=693eaf52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "693eaf52",
  null
  
)

export default component.exports