<template>
  <div :class="['widget-box']">
    <div :class="['title-style', this.titleStyle]" v-if="hasTitleSlot">
      <slot name="title"></slot>
    </div>
    <div class="body-style" :style="{ overflow: bodyOverflow }">
      <slot name="body"></slot>
    </div>
    <div class="footer-style">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    corners: {
      default: 'normal',
    },
    bodyOverflow: {
      default: 'auto'
    },
    titleStyle: {
      default: 'normal'
    }
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title
    },
  }
}
</script>

<style lang="scss" scoped>
.widget-box {
  // position: relative;
  height: 100%;
  width: 100%;
  border: 3px solid var(--v-mapWidgetBorder-base);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.normal {
    border-radius: 0px 0px 20px 20px !important;
  }

  &.flat {
    border-radius: 0px !important;
  }

  &.rounded {
    border-radius: 12px !important;
  }

  .title-style {
    color: var(--v-widgetFontColor-base);
    text-align: center;
    background-color: var(--v-widgetTitleBackground-base);
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.normal {
      font-weight: 500;
      font-size: 1.2em;
      height: 40px;
    }
  }

  .body-style {
    flex: auto;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    background-color: var(--v-mapwidgetBackgroundColor-base) !important;
    color: var(--v-widgetFontColor-base) !important;
  }
  .footer-style {
    flex: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: right;
  }
}
</style>
